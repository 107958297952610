<template>
<div class="form-page">
    <choiceLang />

    <div class="container step-page">

        <div>
            <h5 class="precautions title" v-if="lang === 'zh'">{{ TitleChinese }}</h5>
            <h5 class="precautions title" v-if="lang === 'en'">{{ TitleEnglish }}</h5>
        </div>

        <div class="inner-card">

            <p v-if="lang === 'zh'" v-html="HtmlChinese"></p>
            <p v-if="lang === 'en'" v-html="HtmlEnglish"></p>

            <div class="check">
                <label>
                    <input type="checkbox" class="mr-2" v-model="checked">
                    <span v-if="lang === 'zh'">我已閱讀以上宣導事項</span>
                    <span v-if="lang === 'en'">I have read and understood the statement above.</span>  
                </label>
            </div>

        </div>

    </div>
    </div>
</template>

<script>
import choiceLang from '@/components/common/choiceLang.vue'
import { mapGetters } from 'vuex'
import { apiGetPersonalHealthAgreeDesign } from '@/apis/template'

export default {
    components: {
        choiceLang
    },
    data() {
        return {
            checked: false,

            TitleChinese: null,
            TitleEnglish: null,
            HtmlChinese: null,
            HtmlEnglish: null,
        }
    },
    computed: {
        ...mapGetters(['lang'])
    },
    watch: {
        checked: function() {
            if(this.checked) {
                this.$router.push('/health')
            }
        }
    },
    methods: {
        getConsentForm() {
            const vm = this;

            vm.$store.dispatch("updateLoading", true);
            apiGetPersonalHealthAgreeDesign()
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        vm.$store.dispatch("updateLoading", false);
                        vm.TitleChinese = response.data.TitleChinese
                        vm.TitleEnglish = response.data.TitleEnglish
                        vm.HtmlChinese = response.data.HtmlChinese
                        vm.HtmlEnglish = response.data.HtmlEnglish
                    }
                })
                .catch((error) => {
                    vm.$store.dispatch("updateLoading", false);
                    vm.$notify({
                        title: "失敗",
                        message: error.response.data,
                        type: "error",
                        duration: 2000,
                    });
                });
        },
    },
    created() {
        this.getConsentForm()
    },
}
</script>

<style lang="scss" scoped>
.step-page {
    min-height: 100vh;
    padding: 20px;
}

.inner-card {
    padding: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    position: relative;
    
    box-shadow: 0 2px 12px 0 argb(0, 0, 0, 10%);
    border: 1px solid #EBEEF5;
    background-color: #FFF;

    // border: 1px solid;

    // p {
    //     font-weight: bolder;
    // }
}

.check {
    text-align: center;
    color: red;
}

.title {
    text-align: center;
}

.ftitle {
    margin-bottom: 0;
}

.precautions {
    font-weight: bolder;
    font-size: 24px;
}

.form-page {
    background-color: #bfe3bf;
    // text-align: center;
    min-height: 100vh;
    padding: 90px 20px;
    // background-image: url(/img/bg.8f0e12a6.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-title {
    display: inline-block;
    background: linear-gradient(rgba(255, 255, 255, 0) 70%, #ffc107 0%) !important;
}

</style>